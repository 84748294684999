import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActiveFacetsComponent, FacetService, IconModule, KeyboardFocusModule } from "@spartacus/storefront";
import { CommonModule } from "@angular/common";
import { I18nModule, RoutingService } from "@spartacus/core";
import { RouterModule } from "@angular/router";
import { map } from "rxjs/operators";

@Component({
  selector: 'cx-active-facets',
  templateUrl: './custom-active-facets.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    KeyboardFocusModule,
    IconModule
  ]
})
export class CustomActiveFacetsComponent extends ActiveFacetsComponent {
    /**
   * TODO: Revisar e resolver esta questão de facets.
   */
  originalState: string = '';
  url$ = this.routingService.getRouterState().pipe(
    map((urlState) => {
      this.originalState = urlState.state.url;
      let modifiedUrl = this.originalState;
      const queryStringIndex = modifiedUrl.indexOf('?');
      if (queryStringIndex !== -1) {
        modifiedUrl = modifiedUrl.slice(0, queryStringIndex);
      }
      modifiedUrl = modifiedUrl
        .split('/')
        .filter((p) => p !== 'covercraft' && p !== 'USD' && p !== 'en')
        ?.join('/');

      modifiedUrl = modifiedUrl.replace(/%20/g, ' ');
      modifiedUrl = decodeURIComponent( modifiedUrl);
      return modifiedUrl;
    })
  );

  constructor(facetService: FacetService,
              private routingService: RoutingService) {
    super(facetService);
  }
}
