import { Component, Input, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { ProductConfiguratorStepsService } from "../../core/facade/product-configurator-steps.service";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { GarageState } from "src/app/spartacus/features/ymm/core/store/garage.state";
import { ProductConfigurationSelectors } from "../../core/store";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { ProductConfigStepType } from "../../core/models/product-configuration.models";
import { MatStyleSelection } from "../../core/store/product-configuration.state";
import { distinctUntilChanged, distinctUntilKeyChanged, map, shareReplay } from "rxjs/operators";

@Component({
  selector: 'personalize-mats',
  templateUrl: 'personalize-mats.component.html',
  styleUrls: ['personalize-mats.component.scss'],
})
export class PersonalizeMatsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  isAnySelected: boolean = false;
  isExpanded: boolean = false;
  @Input() isAutomotiveLogo: boolean;
  showAutomotiveLogo: boolean;
  @Input() isPersonalizedEmbroidery: boolean;
  showPersonalizedEmbroidery: boolean;

  isPlainMats: boolean = false;
  numOfOptions: number = 1;

  selectedMatStyles: MatStyleSelection[];

  constructor(
    private store: Store<GarageState>,
    private service: ProductConfigurationService,
    private stepService: ProductConfiguratorStepsService, 
  ) {}

  matStyles$ = this.service.getConfigSelection()
  .pipe(
    map(selection => selection.selections
      ?.filter(s => s.stepType === ProductConfigStepType.MAT_STYLES) as MatStyleSelection[]
    ),
    shareReplay(1),
    distinctUntilChanged((prevMatStyles, currMatStyles) => 
      this.areMatStylesEqual(prevMatStyles, currMatStyles))
  );

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(ProductConfigurationSelectors.selectPersonalizeMatsState).subscribe((state) => {     
        this.isAutomotiveLogo = state.isAutomotiveLogo;
        this.isPersonalizedEmbroidery = state.isPersonalizedEmbroidery;
        this.showAutomotiveLogo = state.showAutomotiveLogo;
        this.showPersonalizedEmbroidery = state.showPersonalizedEmbroidery;
        this.isPlainMats = state.isPlainMats;

        this.updateNumOfOptions();
      })
    );

    this.subscription.add(
      this.matStyles$.subscribe(matStyles => {    
        this.selectedMatStyles = matStyles;       
        this.checkSelectedMatStyles();
        this.updateStore();   
      })
    );

    this.subscription.add(
      this.stepService.showAutomotiveLogo$.subscribe(showLogo => {
        this.showAutomotiveLogo = showLogo;
        this.updateStore();
      })
    );

    this.subscription.add(
      this.stepService.showPersonalizedEmbroidery$.subscribe(showEmbroidery => {
        this.showPersonalizedEmbroidery = showEmbroidery;
        this.updateStore();
      })
    );

    this.updateNumOfOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isAutomotiveLogo'] || changes['isPersonalizedEmbroidery']) {
      this.checkSelectedMatStyles();
      this.updateStore();
    }
  }
  
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private updateNumOfOptions() {
    this.numOfOptions = 1;
    if (this.isAutomotiveLogo) {
      this.numOfOptions++;
    }
    if (this.isPersonalizedEmbroidery) {
      this.numOfOptions++;
    }
  }

  private updateStore() {
    this.service.updatePersonalizedMats({
      isAutomotiveLogo: this.isAutomotiveLogo,
      isPersonalizedEmbroidery: this.isPersonalizedEmbroidery,
      showAutomotiveLogo: this.showAutomotiveLogo,
      showPersonalizedEmbroidery: this.showPersonalizedEmbroidery,
      isPlainMats: this.isPlainMats,
    })
          
    this.isAnySelected = this.showAutomotiveLogo || this.showPersonalizedEmbroidery || this.isPlainMats;
  }

  toggleLogo() {
    this.showAutomotiveLogo = !this.showAutomotiveLogo;
    if (this.showAutomotiveLogo) {
      this.showPersonalizedEmbroidery = false;
      this.stepService.updateShowPersonalizedEmbroideryAccordion(false);
      this.service.clearPersonalizedEmbroidery();
      this.isPlainMats = false;
    }

    this.stepService.updateShowAutomotiveLogoAccordion(this.showAutomotiveLogo);
    this.updateStore();
  }

  toggleEmbroidery() {
    this.showPersonalizedEmbroidery = !this.showPersonalizedEmbroidery;
    if (this.showPersonalizedEmbroidery) {
      this.showAutomotiveLogo = false;
      this.stepService.updateShowAutomotiveLogoAccordion(false);
      this.service.clearAutomotiveLogo();
      this.isPlainMats = false;
    }

    this.stepService.updateShowPersonalizedEmbroideryAccordion(this.showPersonalizedEmbroidery);
    this.updateStore();
  }

  togglePlain() {
    this.isPlainMats = !this.isPlainMats;
    if (this.isPlainMats) {
      this.showAutomotiveLogo = false;
      this.stepService.updateShowAutomotiveLogoAccordion(false);
      this.service.clearAutomotiveLogo();
      this.showPersonalizedEmbroidery = false;
      this.stepService.updateShowPersonalizedEmbroideryAccordion(false);
      this.service.clearPersonalizedEmbroidery();
    }
    this.updateStore();
  }

  toggleAccordion() {
    this.isExpanded = !this.isExpanded;
  }

  private checkSelectedMatStyles() {
    let hasSingleThirdRowMat = false;
    let hasLuxeProductMat = false;

    if (this.selectedMatStyles && this.selectedMatStyles.length === 1) {
      const matStyle = this.selectedMatStyles[0].option;
      hasSingleThirdRowMat = this.isThirdRowMat(matStyle.userInterfaceDescription);
      hasLuxeProductMat = this.isLuxeProduct(matStyle.basePartNumber);
    }
  
    this.isAutomotiveLogo = this.isAutomotiveLogo && !hasSingleThirdRowMat;
    this.isPersonalizedEmbroidery = this.isPersonalizedEmbroidery && !hasLuxeProductMat;
  }

  private areMatStylesEqual(prevMatStyles: MatStyleSelection[], currMatStyles: MatStyleSelection[]): boolean { 
    if (!prevMatStyles || !currMatStyles) return false;

    if (prevMatStyles.length !== currMatStyles.length) return false;

    for (let i = 0; i < prevMatStyles.length; i++) {
        if (JSON.stringify(prevMatStyles[i].option) !== JSON.stringify(currMatStyles[i].option)) {
            return false;
        }
    }

    return true;
  }

  private isThirdRowMat(description: string): boolean {
    const thirdRowDescriptions = [
        MatStyleDescriptions.ThirdRowNumber,
        MatStyleDescriptions.ThirdRowText
    ].map(desc => desc.toLowerCase());

    return thirdRowDescriptions.includes(description.toLowerCase());
  }
  
  private isLuxeProduct(basePartNumber: string): boolean {
    return basePartNumber.startsWith(ProductBasePartNumberPrefixes.Luxe);
  }
}

export const MatStyleDescriptions = {
  ThirdRowNumber: "3d row",
  ThirdRowText: "third row"
};

export const ProductBasePartNumberPrefixes = {
  Luxe: "LU"
};
