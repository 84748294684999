<ng-container *ngIf="showAutomotiveLogo$ | async as showAutomotiveLogo">
  <div class="accordion-wrapper">
    <ng-container *ngIf="automotiveLogo$ | async as automotiveLogo">
      <configurator-accordion-item [title]="'productConfiguration.automotiveLogo.title' | cxTranslate"
                                   [isAnySelected]="(selectedLogo$ | async) !== undefined"
                                   [step]="step"
                                   [numberOfOptions]="automotiveLogo.logos.length">
        <nav class="d-flex mt-4" style="flex-direction: column;">
          <div class="instruction">{{ "productConfiguration.automotiveLogo.step2Instruction" | cxTranslate }}</div>
          <article class="configurator-list">
            <button
              (click)="selectItem(logo)"
              *ngFor="let logo of automotiveLogo.logos"
              class="configurator-card">

              <!-- Media -->
              <div class="configurator-card__media">
                <cx-media [container]="logo.image"></cx-media>
              </div>

              <div class="configurator-card-neck__price">
                <div class="configurator-card-neck__price--value">
                  {{
                  logo.priceWithDiscounts?.value
                    ? logo.priceWithDiscounts.formattedValue
                    : logo.price?.formattedValue
                  }}
                </div>
                <div>
                  <config-select-icon [isSelected]="logo.logoCode === (selectedLogo$ | async)?.logo?.logoCode">
                  </config-select-icon>
                </div>
              </div>

            </button>
          </article>
          <div class="logos-divider"></div>
        </nav>
        <ng-container *ngIf="price$ | async as price">
          <section class="configurator-footer d-none">
            <div class="configurator-footer__price">
              {{ price.formattedValue }}
            </div>
          </section>
        </ng-container>
      </configurator-accordion-item>
    </ng-container>
    <ng-container *ngIf="appliedLogoMatStyle$ | async as applied">
      <ng-container *ngIf="applied.selectedLogo && applied.matStyles">
        <configurator-accordion-item [title]="'productConfiguration.applyAutomotiveLogo.title' | cxTranslate"
                                     [isAnySelected]="applied.selectedMatStyles?.length > 0"
                                     [isOpen]="(selectedLogo$ | async) !== undefined"
                                     [numberOfOptions]="applied.matStyles.length">
          <nav class="d-flex mt-4" style="flex-direction: column;">
            <article class="apply-container">
              <div class="instruction">{{ 'productConfiguration.applyAutomotiveLogo.subTitle' | cxTranslate }}</div>
              <div class="buttons">
                <button class="btn btn-secondary"
                        (click)="applyLogo(matStyle)"
                        *ngFor="let matStyle of applied.matStyles"
                        [ngClass]="{'selected': isSelected(matStyle.option.basePartNumber, applied.selectedMatStyles)}">
                  {{ matStyle.option.userInterfaceDescription }}
                </button>
              </div>
            </article>
            <div class="logos-divider"></div>
          </nav>
        </configurator-accordion-item>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
