import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from "@angular/core";
import {ProductConfigurationService} from "../../core/facade/product-configuration.service";
import {CurrencyPipe} from "@angular/common";
import {ProductConfiguratorStepsService} from "../../core/facade/product-configurator-steps.service";
import {Price, WindowRef} from "@spartacus/core";
import {STEP_INDEX} from "../../dialog/product-config-dialog/product-config-dialog.component";
import {BehaviorSubject, combineLatest} from "rxjs";
import {
  MatStyleOption,
  PersonalizedEmbroidery,
  PersonalizedEmbroideryColor,
  PersonalizedEmbroideryFont,
  ProductConfigStepType
} from "../../core/models/product-configuration.models";
import {map, shareReplay, startWith, take} from "rxjs/operators";
import {MatStyleSelection, PersonalizedEmbroiderySelection} from "../../core/store/product-configuration.state";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'personalized-embroidery',
  templateUrl: 'personalized-embroidery.component.html',
  styleUrls: ['personalized-embroidery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PersonalizedEmbroideryComponent {
  showPersonalizedEmbroidery$ = this.stepService.showPersonalizedEmbroidery$;
  price$ = new BehaviorSubject<Price>(undefined);
  selectedColor: PersonalizedEmbroideryColor;
  selectedFont: PersonalizedEmbroideryFont;
  line1: string = '';
  line2: string = '';

  matStyles$ = this.service.getConfigSelection().pipe(
    map(selection => selection.selections
      ?.filter(s => s.stepType === ProductConfigStepType.MAT_STYLES) as MatStyleSelection[]
    ),
    shareReplay(1),
  );

  personalizedEmbroidery$ = this.service.getConfigurationForCurrentProduct().pipe(
    map((config) => config.steps?.find(
      (s) => s.stepType === ProductConfigStepType.PERSONALIZED_EMBROIDERY
    ) as PersonalizedEmbroidery),
    shareReplay(1)
  );

  embroideryCost$ = this.personalizedEmbroidery$.pipe(
    map(embroidery => {
      return embroidery.priceWithDiscounts ? embroidery.priceWithDiscounts : embroidery.price;
    })
  )

  selectedEmbroidery$ = this.service.getConfigSelection().pipe(
    map(selection => selection.selections?.find(
      s => s.stepType === ProductConfigStepType.PERSONALIZED_EMBROIDERY
    ) as PersonalizedEmbroiderySelection),
    shareReplay(1)
  );

  appliedColorFontMatStyle$ = combineLatest([this.matStyles$, this.selectedEmbroidery$]).pipe(
    map(([matStyle, selectedEmbroidery]) => {
      const selectedMatStyles = selectedEmbroidery?.selectedMatStyles;
      return {
        matStyle,
        selectedEmbroidery,
        selectedMatStyles
      };
    }),
    shareReplay(1)
  );

  embroideryForm: UntypedFormGroup = this.fb.group({
    line1: [ '', [Validators.required, Validators.maxLength(10)] ],
    line2: [ '', Validators.maxLength(10) ],
  });

  constructor(
    private service: ProductConfigurationService,
    private currencyPipe: CurrencyPipe,
    private stepService: ProductConfiguratorStepsService,
    private winRef: WindowRef,
    protected fb: UntypedFormBuilder,
    @Inject(STEP_INDEX) public step: number
  ) {}

  selectColor(color: PersonalizedEmbroideryColor) {
    combineLatest([this.selectedEmbroidery$, this.personalizedEmbroidery$]).pipe(take(1)).subscribe(
      ([selectedEmbroidery, personalizedEmbroidery]) => {
        const e: PersonalizedEmbroiderySelection = {
          stepType: ProductConfigStepType.PERSONALIZED_EMBROIDERY,
          textLine1: selectedEmbroidery?.textLine1,
          textLine2: selectedEmbroidery?.textLine2,
          color: selectedEmbroidery?.color,
          font: selectedEmbroidery?.font,
          price: selectedEmbroidery?.price,
          embroidery: personalizedEmbroidery,
          selectedMatStyles: selectedEmbroidery?.selectedMatStyles
        }
        this.line1 = selectedEmbroidery?.textLine1;
        this.line2 = selectedEmbroidery?.textLine2;
        if (selectedEmbroidery?.color?.code === color.code) {
          e.color = undefined;
          this.selectedColor = undefined;
          this.service.setPersonalizedEmbroidery(e);
        } else {
          if (this.winRef.isBrowser()) {
            const $elements = document.querySelectorAll('.js-show-container');
            $elements.forEach(function ($el) {
              $el.classList.remove('show-container');
            });
          }
          e.color = color;
          this.service.setPersonalizedEmbroidery(e);
          this.selectedColor = color;
          this.stepService.setSubstep(2);
        }
      });
  }

  selectFont(font: PersonalizedEmbroideryFont) {
    combineLatest([this.selectedEmbroidery$, this.personalizedEmbroidery$]).pipe(take(1)).subscribe(
      ([selectedEmbroidery, personalizedEmbroidery]) => {
        const e = selectedEmbroidery;
        if (selectedEmbroidery?.font?.code === font.code) {
          e.font = undefined;
          this.selectedFont = undefined;
          this.service.setPersonalizedEmbroidery(e);
        } else {
          e.font = font;
          this.service.setPersonalizedEmbroidery(e);
          this.selectedFont = font;
        }
      });
  }

  updateLine1() {
    combineLatest([this.selectedEmbroidery$, this.personalizedEmbroidery$]).pipe(take(1)).subscribe(
      ([selectedEmbroidery, personalizedEmbroidery]) => {
        const selectedE = selectedEmbroidery;
        if (this.line1 && this.line1.length > 0 && this.line1.length <= 10) {
          selectedE.textLine1 = this.line1;
          selectedE.price = personalizedEmbroidery.priceWithDiscounts ? personalizedEmbroidery.priceWithDiscounts : personalizedEmbroidery.price;
          this.service.setPersonalizedEmbroidery(selectedE);
        } else if (this.line1 === '') {
          selectedE.textLine1 = undefined;
          selectedE.price = {
            value: 0,
            formattedValue: this.currencyPipe.transform(0)
          }
          this.service.setPersonalizedEmbroidery(selectedE);
        }
      });
  }

  updateLine2() {
    combineLatest([this.selectedEmbroidery$, this.personalizedEmbroidery$]).pipe(take(1)).subscribe(
      ([selectedEmbroidery, personalizedEmbroidery]) => {
        const selectedE = selectedEmbroidery;
        if (this.line2 && this.line2.length > 0 && this.line2.length <= 10) {
          selectedE.textLine2 = this.line2;
          const price = personalizedEmbroidery.priceWithDiscounts?.value ? personalizedEmbroidery.priceWithDiscounts?.value : personalizedEmbroidery.price.value;
          selectedE.price = {
            value: price * 2,
            formattedValue: this.currencyPipe.transform(price * 2)
          }
          this.service.setPersonalizedEmbroidery(selectedE);
        } else if (this.line2 === '') {
          selectedE.textLine2 = undefined;
          selectedE.price = personalizedEmbroidery.priceWithDiscounts ? personalizedEmbroidery.priceWithDiscounts : personalizedEmbroidery.price;
          this.service.setPersonalizedEmbroidery(selectedE);
        }
      });
  }

  applyEmbroidery(matStyle: MatStyleSelection) {
    this.selectedEmbroidery$.pipe(take(1)).subscribe(selectedEmbroidery => {
      const selectedE = selectedEmbroidery;
      if (!selectedEmbroidery.selectedMatStyles) {
        let selectedMS: MatStyleOption[] = [];
        selectedMS.push(matStyle.option);
        selectedE.selectedMatStyles = selectedMS;
        this.service.setPersonalizedEmbroidery(selectedE);
      }
      else {
        let isApplied = selectedE.selectedMatStyles?.some(m => m.basePartNumber === matStyle.option.basePartNumber);
        if (isApplied) {
          selectedE.selectedMatStyles = selectedE.selectedMatStyles.filter(m => m.basePartNumber !== matStyle.option.basePartNumber);
        } else {
          selectedE.selectedMatStyles.push(matStyle.option);
        }
        this.service.setPersonalizedEmbroidery(selectedE);
      }
    })
  }

  isMatSelected(basePartNumber: string, selectedMatStyles: MatStyleOption[]) {
    return selectedMatStyles?.some(s => basePartNumber === s.basePartNumber);
  }

  isMatDisabled(basePartNumber: string) {
    return basePartNumber.startsWith('LU');
  }

  private selectPrice = (item: PersonalizedEmbroidery) => {
    return !item
      ? { value: 0, formattedValue: '$0.00' }
      : item.priceWithDiscounts?.value > 0
        ? item.priceWithDiscounts
        : item.price;
  };
}
