import { Injectable } from "@angular/core";
import { BreadcrumbMeta, CmsService, Page, PageMetaService, TranslationService } from "@spartacus/core";
import { OrderDetailsService } from "@spartacus/order/components";
import { CurrentProductService, ProductListComponentService } from "@spartacus/storefront";
import { combineLatest, EMPTY, Observable } from "rxjs";
import { map, shareReplay, startWith, switchMap } from "rxjs/operators";
import { GarageService } from "src/app/spartacus/features/ymm/core/facade/garage.service";

@Injectable({
  providedIn: 'root',
})
export class CustomBreadCrumbService {
  crumbs$ = this.cmsService.getCurrentPage().pipe(
    switchMap((page) => {
      return this.createCrumbs(page);
    })
  );

  private homeCrumb$ = this.translation.translate('common.home').pipe(
    map((textHome) => ({ label: textHome, link: '/' })),
    shareReplay(1)
  );

  private allLayoutPage$ = combineLatest([
    this.pageMetaService.getMeta().pipe(
      startWith(undefined),
    ),
    this.translation.translate('common.home'),
    this.cmsService.getCurrentPage()
  ]).pipe(
    map(([meta, textHome, page]) => {
      if (page?.typeCode == 'CategoryPage' && page.template == 'LandingPage2Template') {
        return [{ label: textHome, link: '/' }, { label: page?.title ?? page?.description }]
      }

      return meta?.breadcrumbs ? meta.breadcrumbs : [{ label: textHome, link: '/' }]
    }
    )
  );

  constructor(
    private cmsService: CmsService,
    private translation: TranslationService,
    private pageMetaService: PageMetaService,
    private garageService: GarageService,
    private productListComponentService: ProductListComponentService,
    private currentProductService: CurrentProductService,
    private orderService: OrderDetailsService
  ) { }

  private createCrumbs(page: Page): Observable<BreadcrumbMeta[]> {
    if (page?.template === `YMMCategoryPageTemplate`) {
      return this.ymmResolver();
    }

    if (page?.template === `ProductDetailsPageTemplate`) {
      return this.pdpResolver();
    }

    if (page?.template === `AccountPageTemplate` && page?.pageId === `order`) {
      return this.orderPageResolver();
    }

    return this.allResolver();
  }

  private ymmResolver(): Observable<BreadcrumbMeta[]> {
    const activeVehicle = this.garageService.getActiveVehicle();
    // if (!activeVehicle) {
    //   return EMPTY;
    // }
    return combineLatest([
      this.homeCrumb$,
      this.garageService.getActiveVehicle(),
      this.cmsService.getCurrentPage(),
      this.productListComponentService.model$
    ]).pipe(
      map(([home, activeVehicle, p, productModel]) => {
  
        let breadcrumbs: any[] = [home];
        const yearFromPage = p.year;
        if (productModel.currentQuery.url.includes('levelOneCategories')) {
          let link = '';
          // YMM/MM category page
          if (!yearFromPage && p.label === 'shop') {
            // MM page
            link = '/shop/' + this.toUrlSlug(`${p.make}-${p.model}`);
            breadcrumbs.push({ label: `${p.make} ${p.model}`, link: link })
          } else {
            // YMM page
            const { year, make, model } = activeVehicle.ymm;
            link = '/shop/' + this.toUrlSlug(`${year}-${make}-${model}`);
            breadcrumbs.push({ label: `${year} ${make} ${model}`, link: link })
          }
          let cats = productModel.currentQuery.url.substring(productModel.currentQuery.url.indexOf('levelOneCategories:'))
            .split('levelOneCategories:')
            .filter(item => item && item.trim() !== '');
          for (let i = 0; i < cats.length; i++) {
            const label = cats[i].replaceAll('-', ' ')
              .replaceAll(':', '')
              .replace(/(^|\s)[a-z]/gi, (l: string) => l.toUpperCase());
            if (i == cats.length - 1) {
              breadcrumbs.push({ label: label });
            } else {
              link += '/' + cats[i].replaceAll(':', '');
              breadcrumbs.push({ label: label, link: link });
            }
          }
          return breadcrumbs;
        } else {
          // YMM/MM page
          if (!yearFromPage && p.label === 'shop') {
            // Currently on MM page - display Make and Model from page
            return [home, { label: `${p.make} ${p.model}` }];
          }
          if (!activeVehicle) {
            return [home, { label: '' }];
          }
          const { year, make, model } = activeVehicle.ymm;
         return [home, { label: `${year} ${make} ${model}` }];
        }
      })
    );
  }

  private pdpResolver(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.translation.translate('common.home'),
      this.cmsService.getCurrentPage(),
      this.currentProductService.getProduct(),
    ]).pipe(
      map(([textHome, page, product]) => {
        let breadcrumb = [{ label: textHome, link: '/' }];

        if (product?.categoryBreadcrumb?.length) {
          product?.categoryBreadcrumb.forEach((breadcrumbItem) => {
            breadcrumb.push({
              label: breadcrumbItem.name,
              link: breadcrumbItem.url,
            });
          });

          if (page.template === 'ProductDetailsPageTemplate') {
            breadcrumb.push({ label: product.name, link: '' });
          }
        }

        return breadcrumb;
      })
    );
  }

  private orderPageResolver(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.homeCrumb$,
      this.orderService.getOrderDetails(),
    ]).pipe(
      map(([home, order]) => {
        if (!order) {
          return [home, { label: '' }];
        }
        return [home, { label: order.code }];
      })
    );
  }

  private allResolver(): Observable<BreadcrumbMeta[]> {
    return this.allLayoutPage$;
  }

  /**
   * create a URL slug from the MM or YMM
   * see src/app/spartacus/features/ymm/core/facade/ymm.service.ts setRequestYmm()
   * (that doesn't work for MM)
   */
  private toUrlSlug(rawStr: string): string {
    return rawStr
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-'); // collapse dashes in case of extras
  }
}