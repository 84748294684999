import { Injectable } from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';
import { Page, PageMeta, Product, ProductSearchPage, User, WindowRef } from "@spartacus/core";
import { Order } from '@spartacus/order/root';
import { environment } from 'src/environments/environment';
import { YMM } from '../ymm/core/models/garage.model';
import { DataLayerBuilder } from './data-layer-builder.service';
import { DlEvent } from './data-layer.model';

/**
 * Class that handles interactions with the data layer
 */

@Injectable({
    providedIn: 'root'
})
export class DataLayerService {
    private window: any;
    private gtmKey: string;
    private gtmEnv: string;

    constructor(
        private winRef: WindowRef,
        private dataLayerBuilder: DataLayerBuilder
    ) {
        if (this.winRef.isBrowser()) {
            this.window = winRef.nativeWindow;
            this.window.dataLayer = this.window.dataLayer || [];
        } else {
            this.window = {};
            this.window.dataLayer = [];
        }

        this.gtmKey = environment.gtmKey;
        this.gtmEnv = environment.gtmEnv ?? 'staging';
    }

    /** push to data layer to fire event */
    public push(dataLayerObject: DlEvent, reset: boolean = false): void {
        //console.log(dataLayerObject);
        if (this.winRef.isBrowser()) {
            this.window.dataLayer.push(dataLayerObject);

            if (reset) {
                this.pushReset();
            }
        }
    }

    /** reset the data layer */
    public pushReset(): void {
        let gtm = this.window.google_tag_manager[this.gtmKey];
        gtm.dataLayer.reset();
    }

    /**
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
     */
    public pushEcomNull(): void {
        this.push({ecommerce:null});
    }

    public pageViewEvent(page: Page, meta: PageMeta={}): void {
        let type = page.type;
        let group = null;
        let skip = ['Home', 'Size', 'Product Line', 'Color'];

        if (!page.productCode) {
            type = page.name;
        }

        if (meta && meta.breadcrumbs) {
            for (let crumb of meta.breadcrumbs) {
                if (!skip.includes(crumb.label)) {
                    group = crumb.label;
                    break;
                }
            }
        }
        
        let dataLayerObject = {
            event: "page_view",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.setContent(group, type)
        };

        this.push(dataLayerObject);
    }

    /** GTM/GA4 search event */
    public searchEvent(model: ProductSearchPage): void {
        let dataLayerObject = {
            event: "search",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.setSearch(
                model.pagination.currentPage + 1,
                model.pagination.totalResults,
                model.freeTextSearch
            )
        };

        this.push(dataLayerObject);
    }

    /** GTM/GA4 login event */
    public loginEvent(user: User): void {
        let dataLayerObject = {
            event: "login",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.setUser(
                user.customerId,
                null,
                null,
                user.uid
            ),
            content: this.dataLayerBuilder.getContent()
        };

        this.push(dataLayerObject);
    }

    /** TODO */
    public logoutEvent(): void {
        this.dataLayerBuilder.setUser(null, null, null, null);
    }

    /** GTM/GA4 sign_up event */
    public registerEvent(): void {
        let dataLayerObject = {
            event: "sign_up",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent()
        };

        this.push(dataLayerObject);
    }

    /** GTM/GA4 register_car event */
    public registerCarEvent(ymm: YMM, count: number): void {
        let ymmFull = ymm.year + ' ' + ymm.make + ' ' + ymm.model;

        let dataLayerObject = {
            event: "register_car",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.setUser(null, count, ymmFull, null),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true)
        };

        this.push(dataLayerObject);
    }

    /** GTM/GA4 delete_car event */
    public deleteCarEvent(): void {
        let dataLayerObject = {
            event: "delete_car",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.setUser(null,null,null,null),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true)
        };

        this.push(dataLayerObject);
    }

    /** GTM/GA4 view_garage event */
    public viewGarageEvent(): void {
        let dataLayerObject = {
            event: "view_garage",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true)
        };

        this.push(dataLayerObject);
    }

    /** GTM/GA4 view_item_list event */
    public viewItemListEvent(products: Array<Product>, listName: string=''): void {
        let dataLayerObject = {
            event: "view_item_list",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(false),
            ecommerce: {
                items: this.dataLayerBuilder.createItemsFromProductList(products, listName)
            }
        }

        this.pushEcomNull();
        this.push(dataLayerObject);
    }

    /* GTM/GA4 select_item event */
    public selectItemEvent(product: Product, variant: any={}): void {
        let dataLayerObject = {
            event: "select_item",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true),
            ecommerce: {
                currency: product?.price?.currencyIso,
                value: variant?.priceWithDiscount?.value || variant?.price?.value || product?.priceWithDiscount?.value || product?.price?.value,
                items: [this.dataLayerBuilder.createItem(product, 1, 0, '', variant)]
            }
        };

        this.pushEcomNull();
        this.push(dataLayerObject);
    }

    /** GTM/GA4 view_item event */
    public viewItemEvent(product: Product, variant: any): void {
        let dataLayerObject = {
            event: "view_item",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true),
            ecommerce: {
                currency: product?.price?.currencyIso,
                value: variant?.priceWithDiscount?.value || variant?.price?.value || product?.priceWithDiscount?.value || product?.price?.value,
                items: [this.dataLayerBuilder.createItem(product, 1, 0, '', variant)]
            }
        };

        this.pushEcomNull();
        this.push(dataLayerObject);
    }

    /** GTM/GA4 confirm_vehicle event */
    public confirmVehicleEvent(): void {
        let dataLayerObject = {
            event: "confirm_vehicle",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true)
        };

        this.push(dataLayerObject);
    }

    /** GTM/GA4 no_fitment event */
    public noFitmentEvent(): void {
        let dataLayerObject = {
            event: "no_fitment",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true)
        }

        this.push(dataLayerObject);
    }

    /** GTM/GA4 add_to_cart event */
    public addToCartEvent(product: Product, variant: any, quantity: number): void {
        let dataLayerObject = {
            event: "add_to_cart",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true),
            ecommerce: {
                currency: product?.price?.currencyIso,
                value: variant?.priceWithDiscount?.value || variant?.price?.value || product.priceWithDiscount?.value || product.price?.value,
                items: [this.dataLayerBuilder.createItem(product, quantity, 0, '', variant)]
            }
        };

        this.pushEcomNull();
        this.push(dataLayerObject);
    }

    /** GTM/GA4 remove_from_cart event */
    public removeFromCartEvent(product: Product, quantity: number): void {
        let dataLayerObject = {
            event: 'remove_from_cart',
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true),
            ecommerce: {
                currency: product?.price?.currencyIso,
                value: product.priceWithDiscount.value,
                items: [this.dataLayerBuilder.createItem(product)]
            }
        }

        this.pushEcomNull();
        this.push(dataLayerObject);
    }

    /** GTM/GA4 view_cart event */
    public viewCartEvent(cart: Cart): void {  
        let dataLayerObject = {
            event: "view_cart",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true),
            ecommerce: this.dataLayerBuilder.createEcommerce(cart)
        };

        this.pushEcomNull();
        this.push(dataLayerObject);
    }

    /** GTM/GA4 pre_checkout event */
    public preCheckoutEvent(): void {
        let dataLayerObject = {
            event: "pre_checkout",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true),
        }

        this.push(dataLayerObject);
    }

    /* GTM/GA4 input_coupon event */
    public inputCouponEvent(coupon: string, valid: boolean): void {
        let dataLayerObject = {
            event: "input_coupon",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true),
            coupon: {
                input: coupon,
                valid: valid
            }
        };
        
        this.push(dataLayerObject);
    }

    /** GTM/GA4 begin_checkout event */
    public beginCheckoutEvent(cart: Cart): void {
        let dataLayerObject = {
            event: "begin_checkout",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true),
            ecommerce: this.dataLayerBuilder.createEcommerce(cart)
        };

        this.pushEcomNull();
        this.push(dataLayerObject);
    }

    /** GTM/GA4 add_address_info event */
    public addAddressInfoEvent(cart: Cart): void {
        let dataLayerObject = {
            event: "add_address_info",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true),
            ecommerce: this.dataLayerBuilder.createEcommerce(cart)
        };

        this.pushEcomNull();
        this.push(dataLayerObject);
    }

    /** GTM/GA4 add_shipping_info event */
    public addShippingInfoEvent(cart: Cart): void {
        let dataLayerObject = {
            event: "add_shipping_info",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true),
            ecommerce: this.dataLayerBuilder.createEcommerce(cart)
        };

        this.pushEcomNull();
        this.push(dataLayerObject);
    }

    /** GTM/GA4 add_payment_info event */
    public addPaymentInfoEvent(cart: Cart): void {
        let dataLayerObject = {
            event: "add_payment_info",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true),
            ecommerce: this.dataLayerBuilder.createEcommerce(cart)
        };

        // TODO
        dataLayerObject.ecommerce.payment_type = cart.paymentType?.code || null;

        this.pushEcomNull();
        this.push(dataLayerObject);
    }

    /** GTM/GA4 purchase event */
    public purchaseEvent(order: Order, user: User): void {
        let dataLayerObject = {
            event: "purchase",
            environment: this.gtmEnv,
            user: this.dataLayerBuilder.getUser(true),
            content: this.dataLayerBuilder.getContent(),
            search: this.dataLayerBuilder.getSearch(true),
            emailAddress: order.paymentInfo?.billingAddress?.email, // for ads conversion tag enhanced conversions
            ecommerce: this.dataLayerBuilder.createEcommerce(order)
        };

        // TODO
        dataLayerObject.ecommerce.payment_type = 'CARD';
        dataLayerObject.ecommerce.order_id = order.code;

        this.pushEcomNull();
        this.push(dataLayerObject);
    }

    /** GTM/GA4 error event */
    public errorEvent(message: string): void {
        let dataLayerObject = {
            event: "error",
            environment: this.gtmEnv,
            content: this.dataLayerBuilder.getContent(),
            error: {
                description: message
            }
        }

        this.push(dataLayerObject);
    }

    /** GTM/GA4 subscribe_newsletter event */
    public subscribeNewsletterEvent(): void {
        let dataLayerObject = {
            event: "subscribe_newsletter",
            environment: this.gtmEnv,
            content: this.dataLayerBuilder.getContent(),
            user: this.dataLayerBuilder.getUser(true),
            search: this.dataLayerBuilder.getSearch(true)
        };

        this.push(dataLayerObject);
    }
}
