import { Injectable } from '@angular/core';
import { DlContent, DlCoupon, DlEcommerce, DlError, DlEvent, DlItem, DlSearch, DlUser } from './data-layer.model';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { first } from 'rxjs/operators';
import { WindowRef } from '@spartacus/core';


/**
 * A class to help with building data layer objects
 */
@Injectable({
    providedIn: 'root'
})
export class DataLayerBuilder {
    private dataLayer: DlEvent;

    constructor(
        private userProfileFacade: UserProfileFacade,
        private winRef: WindowRef
    ){
        this.dataLayer = this.loadDataLayer();
    }

    public setContent(
        group: string=null,
        type: string=null,
        navSource: string=null
    ): DlContent {
        this.dataLayer.content = {
            group: group,
            type: type,
            navSource: navSource
        } as DlContent;

        this.storeDataLayer();
        return this.dataLayer.content;
    }

    public getContent(): DlContent {
        return this.dataLayer.content ?? null;
    }

    public setSearch(
        page: number=null,
        results: number=null,
        term: string=null,
        type: string=null
    ): DlSearch {
        this.dataLayer.search = {
            page: page,
            results: results,
            term: term,
            type: type,
        };

        this.storeDataLayer();
        return this.dataLayer.search;
    }

    public getSearch(termOnly: boolean): DlSearch {
        if (termOnly) {
            return {
                term: this.dataLayer.search?.term ?? null
            } as DlSearch;
        } else {
            return this.dataLayer.search;
        }
    }

    public setUser(
        userId: string=null,
        count: number=null,
        ymm: string=null,
        email: string=null
    ): DlUser {
        let garage = this.getGarage();

        // TODO figure out a way to get rid of this
        if (!userId) {
            this.userProfileFacade.get().pipe(first()).subscribe(user => {
                if (user) {
                    userId = user.customerId;
                    email = user.uid;
                }
            });
        }

        this.dataLayer.user = {
            id: userId || null,
            garage: count || garage.count || null,
            car: ymm || garage.ymm || null,
            email: email || null 
        };

        this.storeDataLayer();
        return this.dataLayer.user;
    }

    // TODO
    public getUser(checkValues: boolean=false): DlUser {
        if (checkValues) {
            this.setUser(null, null, null, null);
        }
        return this.dataLayer.user;
    }

    public createEcommerce(data: any): DlEcommerce {
        // Handle applied vouchers safely
        const couponCode = data.appliedVouchers && data.appliedVouchers.length > 0
            ? data.appliedVouchers[0].code
            : '';

        let ecommerceObject: DlEcommerce = {
            currency: data?.totalPrice?.currencyIso,
            value: data?.totalPriceWithTax?.value || data?.totalPrice?.value,
            coupon: couponCode,
            discount: data.totalDiscounts?.value || null,
            ship_tier: data.deliveryMode?.code || null,
            ship_amount: data.deliveryCost?.value || null,
            //payment_type: data.paymentType?.code || null,
            payment_type: null,
            //order_id: data?.code,
            order_id: null,
            order_tax: data.totalTax?.value || null,
            coupon_discount: data.orderDiscounts?.value || null,
            items: this.createItemsFromEntries(data.entries)
        }

        return ecommerceObject;
    }

    public createItemsFromProductList(products: any, listName: string=''): DlItem[] {
        let index = 0;
        let formattedItems: DlItem[] = [];

        products.forEach((product: any) => {
            let newItem = this.createItem(product, 1, index, '', {}, '', listName);
            formattedItems.push(newItem);
            index++;
        });

        return formattedItems;
    }

    public createItemsFromEntries(entries: any): DlItem[] {
        let index = 0;
        let formattedItems: DlItem[] = [];

        entries.forEach((entry: any) => {
            let newItem = this.createItem(
                entry.product,
                entry.quantity,
                index,
                '',
                {},
                entry.partNumber
            );
            formattedItems.push(newItem);
            index++;
        });
        
        return formattedItems;
    }

    public createItem(
        product: any,
        quantity: number=1,
        index: number=0,
        coupon: string='',
        variant: any={},
        sku: string='',
        listName: string=''
    ): DlItem {
        let itemName = '';

        // if it's a generic variant product
        if (product.baseProduct && product.baseProduct != product.code) {
            // TODO: need base product name.  Don't think this is reliable enough
            // variant products use something like {baseProductName} - {category} - {more category}
            // item_name needs to be consistent across events, else it will break the GA4 funnels
            itemName = product.name.substring(0, product.name.indexOf(" - "));
            itemName.length === 0 ? itemName = product.name : itemName = itemName;
        } else {
            itemName = product.name
        }
        
        let categoryArray = this.getCategoryArray(
            //product?.categories || [],
            product?.categoryBreadcrumb || [],
            product?.googleProductCategory || ''
        );

        let qualifiers = null;
        if (variant.code) {
            let selected = product?.baseOptions[0]?.options?.find((item: any) => item.code === variant.code);
            qualifiers = selected?.variantOptionQualifiers || null;
        }
        if (!qualifiers) {
            qualifiers = product?.baseOptions?.[0]?.selected.variantOptionQualifiers;
        }

        let fits = null;
        if (product.vehicleSpecific === false) {
            fits = true;
        } else {
            fits = product.fit;
        }

        let item: DlItem = {
            list_name: listName || this.determineList(),
            coupon: coupon,
            position: index,
            id: product?.baseProduct || product?.code || '',
            name: itemName,
            compatible: fits,
            variant: qualifiers ? qualifiers.map((qual: { name: any; }) => qual.name).join(', ') : '',  // TODO
            variant_id: variant?.code || product?.baseOptions?.[0]?.selected?.code || '',
            part_id: sku.replace("{logo}", "") || '', // TODO
            brand: product?.brand || '',
            category: categoryArray[0] || '',
            category2: categoryArray[1] || '',
            category3: categoryArray[2] || '', 
            category4: categoryArray[3] || '',
            category5: categoryArray[4] || '',
            discount: variant?.discount?.value || product.discount?.value || 0, 
            price: variant?.priceWithDiscount?.value || variant?.price?.value || product?.priceWithDiscount?.value || product?.price?.value,
            quantity: quantity
        };

        return item;
    }

    private getCategoryArray(
        categories: any,
        googleProductCategory: string=''
    ): Array<string> {
        let categoryArray = categories?.map((category: { name: any; }) => category?.name) || [];
        let ignore = ['Color', 'Size'];

        categoryArray = categoryArray.filter((cat: string) => !ignore.includes(cat));
        googleProductCategory && categoryArray.unshift(googleProductCategory);

        return categoryArray;
    }

    private getGarage(): any {
        if (this.winRef.isBrowser()) {
            let garage = JSON.parse(this.winRef.localStorage.getItem('spartacus⚿⚿garage'));
            let num = 0;
            let active = '';

            if (garage.vehicles?.garage) {
                num =  Object.keys(garage.vehicles.garage).length;
            }

            if (garage.vehicles?.active) {
                active = garage.vehicles?.active?.replace(/\|/g, ' ');
            }

            return {
                count: num,
                ymm: active,
                fit: garage.fit || null
            };
        } else {
            return {};
        }
    }

    public storeDataLayer(): void {
        if (this.winRef.isBrowser()) {
            this.winRef.sessionStorage.setItem('spartacus⚿⚿data⚿⚿layer', JSON.stringify(this.dataLayer));
        }
    }

    public loadDataLayer(): DlEvent {
        let dataLayer = {} as DlEvent;

        if (this.winRef.isBrowser()) {
            let dlString = this.winRef.sessionStorage.getItem('spartacus⚿⚿data⚿⚿layer') ?? null;

            if (dlString != null) {
                dataLayer = JSON.parse(dlString) as DlEvent;
            }
        }

        return dataLayer;
    }

    private determineList(): string {
        let listName = '';
        let page = this.dataLayer.content?.type || '';

        if (page.includes('Search')) {
            listName = 'Search PLP';
        } else if (page.includes('YMM')) {
            listName = 'YMM PLP';
        } else if (page.includes('Category')) {
            listName = 'Category PLP';
        }

        return listName;
    }
}
