import { Component } from '@angular/core';
import { BreadcrumbMeta, CmsBreadcrumbsComponent, CmsService, PageMetaService } from '@spartacus/core';
import { CmsComponentData, PageLayoutService, PageTitleComponent, ProductListComponentService } from '@spartacus/storefront';
import { combineLatest } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { CustomPageMetaService } from 'src/app/core/cms/facade/page-meta.service';
import { ToolsService } from 'src/app/components/tools.service';
import { GarageService } from "../../../../../../spartacus/features/ymm/core/facade/garage.service";
import { VehicleType, vehicleTypeImageMap } from "../../../../../../spartacus/features/ymm/core/models/garage.model";

@Component({
  selector: 'page-title-plp',
  templateUrl: './page-title-plp.component.html',
  styleUrls: [ './page-title-plp.component.scss' ],
})

export class PageTitlePLPComponent extends PageTitleComponent {
  component$ = this.component.data$;
  originalTitle$ = this.pageLayoutService.page$;
  lastYmmTitle = '';

  vehicleImg: string = '';

  contentWithTitle$ = combineLatest([
    this.pageMetaService.getMeta(),
    this.pageLayoutService.page$,
  ]).pipe(map(([ meta, pageLayout ]: any) => ( { meta, pageLayout } )));

  customTitle$ = combineLatest([
    this.contentWithTitle$.pipe(startWith(null)),
    this.cmsService.getCurrentPage(),
    this.garageService.getActiveVehicle().pipe(startWith(null)),
    this.productListComponentService.model$,
    this.pageMetaService.getMeta().pipe(startWith(null))
  ]).pipe(
    map(([ contentWithTitle, page, activeVehicle, productModel, meta ]: any) => {
      const getTitle = () => {
        if (page.template === 'SearchResultsListPageTemplate' && !productModel.products.length) {
          return undefined;
        }

        if (page.template === 'YMMCategoryPageTemplate') {
          if (!activeVehicle) {
            if (page.label === 'shop' && !page.year) {
              // MM page without active vehicle - display vehicle image from MM vehicle type
              // @ts-ignore
              let vType: VehicleType = VehicleType[page?.vehicleType?.toString().toUpperCase()];
              this.vehicleImg = vehicleTypeImageMap.get(vType);
              // MM page without active vehicle - display Make and Model from current page
              return `${ page.make } ${ page.model }`;
            } else {
              return this.lastYmmTitle;
            }
          }

          const { year, make, model } = activeVehicle?.ymm;
          if (page.year) {
            if (activeVehicle) {
              this.vehicleImg = activeVehicle.image;
            }
            // YMM page - display active vehicle's YMM
            return `${ year } ${ make } ${ model }`;
          } else {
            // MM page - display vehicle image from MM vehicle type
            // @ts-ignore
            let vType: VehicleType = VehicleType[page?.vehicleType?.toString().toUpperCase()];
            this.vehicleImg = vehicleTypeImageMap.get(vType);
            // MM page - display Make and Model from current page
            return `${ page.make } ${ page.model }`;
          }

        }

        if (page.template === 'ProductListPageTemplate' && meta?.breadcrumbs?.length) {
          const sliceOfMeta: BreadcrumbMeta = this.toolsService.getLastItemFromArray(meta?.breadcrumbs);

          if (sliceOfMeta?.label) {
            return sliceOfMeta.label;
          }
        }

        return decodeURIComponent(contentWithTitle.meta?.heading || contentWithTitle.meta?.title || contentWithTitle.pageLayout.name || '');
      }

      const title = getTitle();
      // set YMM/MM page title as "{YMM} {Category Name} - Covercraft" or "{MM} {Category Name} - Covercraft"
      if (page.template === 'YMMCategoryPageTemplate' && page.label === 'shop') {
        if (productModel.currentQuery.url.includes('levelOneCategories')) {
          let cats = productModel.currentQuery.url.substring(productModel.currentQuery.url.indexOf('levelOneCategories:'));
          cats = cats.replaceAll('levelOneCategories:', ' ').replaceAll('-', ' ').replaceAll(':', '');
          cats = cats.replace(/(^|\s)[a-z]/gi, (l: string) => l.toUpperCase());
          this.customPageMetaService.setPageTitle(title + cats + ' - Covercraft');
        } else {
          this.customPageMetaService.setPageTitle(title + ' - Covercraft');
        }
      } else {
        this.customPageMetaService.setPageTitle(title);
      }

      return title
    })
  );

  eyebrow$ = combineLatest([
    this.pageMetaService.getMeta(),
    this.cmsService.getCurrentPage(),
  ]).pipe(
    map(([ meta, page ]) => meta.eyebrow)
  );

  ymmCategoryEyebrow = '';


  isYmmCategoryPage$ = combineLatest([
    this.productListComponentService.model$,
    this.pageLayoutService.page$
  ]).pipe(
    take(1),
    map(([productModel, page]) => {
      let cats = productModel.currentQuery.url.substring(productModel.currentQuery.url.indexOf('levelOneCategories:'))
        .split('levelOneCategories:')
        .filter(item => item && item.trim() !== '');
      if (cats.length > 0) {
        this.ymmCategoryEyebrow = cats[0].replaceAll('-', ' ').replaceAll(':', ' ');
      }
      return page.template === 'YMMCategoryPageTemplate' && page.label === 'shop' && productModel.currentQuery.url.includes('levelOneCategories');
    })
  );

  constructor(
    component: CmsComponentData<CmsBreadcrumbsComponent>,
    pageMetaService: PageMetaService,
    private productListComponentService: ProductListComponentService,
    private cmsService: CmsService,
    private garageService: GarageService,
    private pageLayoutService: PageLayoutService,
    private customPageMetaService: CustomPageMetaService,
    private toolsService: ToolsService
  ) {
    super(component, pageMetaService);
  }
}
