import { Injectable } from "@angular/core"
import type { FacebookQuery, AddToCartData, FacebookQueryExtra, PurchaseData, ContentsData, ViewContentData, InitiateCheckoutData } from "./typings"
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from "rxjs";
import { catchError, filter, finalize, map, mapTo, shareReplay, startWith, switchMap, take, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService, OccEndpointsService, User, WindowRef } from "@spartacus/core";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { Cart } from "@spartacus/cart/base/root/models";
import { Order, OrderFacade } from "@spartacus/order/root";


export * from './typings'

@Injectable({ providedIn: 'root' })
export class MetaPixel {
    private subscription: Subscription = Subscription.EMPTY;

    userEmail?: string;
    user$?: Observable<User | undefined>;
    metaPixelId!: string;
    end$: Observable<any>;
    navigationEnd$: Observable<any>;
    facebookQuery: FacebookQuery;  
    initCode$: Observable<any>;
    navigationEndSubject = new BehaviorSubject<string | null>(null);

    constructor(
        private auth: AuthService,
        private userAccountFacade: UserAccountFacade,    
        private occEndpointsService: OccEndpointsService,
        private http: HttpClient,
        private router: Router,
        private activeCartService: ActiveCartFacade,
        private orderFacade: OrderFacade,
        private winRef: WindowRef) {
        this.metaPixelId = environment.metaPixelId

        if (this.winRef.isBrowser()) {
            this.facebookQuery =  this.addScript(this.winRef.nativeWindow, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

            this.router.events.pipe(
                filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                map(event => event.urlAfterRedirects)
            ).subscribe(url => {
                this.navigationEndSubject.next(url);
            });
        
            this.navigationEnd$ = this.navigationEndSubject.asObservable().pipe(
                startWith(null),
                shareReplay(1)
            );

            this.user$ = this.auth.isUserLoggedIn().pipe(       
                switchMap((isUserLoggedIn) => {
                    if (isUserLoggedIn) {
                        return this.userAccountFacade.get();
                    } else {    
                        return of(null);
                    }
                })
            );

            this.initCode$ = this.user$.pipe(       
                tap(user => {
                if (user?.name) {
                    this.userEmail = user.uid;
                }
                this.addInitCode();
                }),
            );

            this.user$.subscribe();


           
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
   
    eventAddToCart(price: number, productCode: string) {                     
        this.initCode$.pipe(
            switchMap(() => this.getCommonMetaBody()),
            take(1)
        ).subscribe((commonBody: CommonMetaBody) => {
                const metaUrl = this.occEndpointsService.buildUrl('metaAddToCart');

                const httpHeaders = new HttpHeaders({
                    'Content-Type': 'application/json',
                });

                const cartEntry = {
                    productCode: productCode,
                    price: price     
                }
                const postBody = {
                    ...commonBody,
                    cartEntry: cartEntry   
                }   

                this.http.post(metaUrl, postBody, { headers: httpHeaders }).subscribe(
                    response => console.log('success:', response),
                    error => console.error('error:', error)
                );     

                let addToCartData: AddToCartData = {
                    value: price,
                    currency: "USD",
                    content_type: "product",
                    content_ids: [productCode],
                }
    
                let faceExtra : FacebookQueryExtra = {
                    eventID: postBody.eventId
                }
       
                return this.facebookQuery("track", "AddToCart", addToCartData, faceExtra ); 
            });        
    }
    
    /*
    eventInitiateCheckout() {                
        var cart$ = this.activeCartService.getActive().pipe(shareReplay(1));
        
        cart$.subscribe((cart: Cart) => {            
            const cartEntries = !cart?.entries ? [] : cart.entries.map((entry) => {
                return { productCode : entry.product.code, quantity: entry.quantity }
            })
        
                this.initCode$.pipe(
                    switchMap(() => this.getCommonMetaBody()),
                    take(1)
                ).subscribe((commonBody: CommonMetaBody) => {               
                    const metaUrl = this.occEndpointsService.buildUrl('metaInitiateCheckout');

                    const httpHeaders = new HttpHeaders({
                        'Content-Type': 'application/json',
                    });

                    const postBody = {...commonBody,
                        numberOfItems: cartEntries.length,
                        cartTotal: cart.totalPrice.value,
                        cartEntries: cartEntries.map((entry) => {
                            return { productCode : entry.productCode, quantity: entry.quantity }
                        })  
                    } 
                                      
                    this.http.post(metaUrl, postBody, { headers: httpHeaders }).subscribe(
                        response => console.log('success:', response),
                        error => console.error('error:', error)
                    );
                        
                    let initiateCheckoutData: InitiateCheckoutData = {   
                        currency: "USD",       
                        value: cart.totalPrice.value,
                        num_items: cartEntries.length,
                        contents: cartEntries.map((entry) => {
                            return { name : entry.productCode, quantity: entry.quantity }
                        }),     
                        content_ids: cartEntries.map(entry=>entry.productCode),
                    }

                    let faceExtra : FacebookQueryExtra = {
                        eventID:postBody.eventId
                    }

                    return this.facebookQuery("track", "InitiateCheckout", initiateCheckoutData, faceExtra ); 
                });     
        });          
    }
    */

    eventInitiateCheckout(): Observable<void> {
        return this.activeCartService.getActive().pipe(      
            switchMap((cart: Cart) => {
                const cartEntries = !cart?.entries ? [] : cart.entries.map((entry) => ({
                    productCode: entry.product.code,
                    quantity: entry.quantity
                }));
    
                return this.initCode$.pipe(
                    switchMap(() => this.getCommonMetaBody()),
                    take(1),
                    switchMap((commonBody: CommonMetaBody) => {
                        const metaUrl = this.occEndpointsService.buildUrl('metaInitiateCheckout');
                        const httpHeaders = new HttpHeaders({
                            'Content-Type': 'application/json',
                        });
    
                        const postBody = {
                            ...commonBody,
                            numberOfItems: cartEntries.length,
                            cartTotal: cart.totalPrice.value,
                            cartEntries: cartEntries.map((entry) => ({
                                productCode: entry.productCode,
                                quantity: entry.quantity
                            }))
                        };
    
                        return this.http.post(metaUrl, postBody, { headers: httpHeaders }).pipe(
                            catchError(error => {
                                console.error('HTTP POST error:', error);
                                return of(null); // Handle HTTP error and continue
                            }),
                            finalize(() => {
                                // Always run Facebook query, regardless of success or error in the HTTP request
                                let initiateCheckoutData: InitiateCheckoutData = {
                                    currency: "USD",
                                    value: cart.totalPrice.value,
                                    num_items: cartEntries.length,
                                    contents: cartEntries.map(entry => ({
                                        name: entry.productCode,
                                        quantity: entry.quantity
                                    })),
                                    content_ids: cartEntries.map(entry => entry.productCode),
                                };
    
                                let faceExtra: FacebookQueryExtra = {
                                    eventID: postBody.eventId
                                };
    
                                this.facebookQuery("track", "InitiateCheckout", initiateCheckoutData, faceExtra);                                                                                                               
                            })
                        );
                    })
                );
            }),
        );
    }        

    eventPurchase(): Observable<void> {       
        return this.orderFacade.getOrderDetails().pipe(      
            switchMap((order: Order) => {
                const orderEntries = !order?.entries ? [] : order.entries.map((entry) => ({
                    productCode: entry.product.code,
                    quantity: entry.quantity
                }));
    
                return this.initCode$.pipe(
                    switchMap(() => this.getCommonMetaBody()),
                    take(1),
                    switchMap((commonBody: CommonMetaBody) => {
                        const metaUrl = this.occEndpointsService.buildUrl('metaPurchase');

                        const httpHeaders = new HttpHeaders({
                            'Content-Type': 'application/json',
                        });

                        const postBody = {
                            ...commonBody,
                            numberOfItems: orderEntries.length,
                            cartTotal: order.totalPrice.value,
                            cartEntries: orderEntries.map((entry) => {
                                return { productCode : entry.productCode, quantity: entry.quantity }
                            })  
                        } 
                                                
                        return this.http.post(metaUrl, postBody, { headers: httpHeaders }).pipe(
                            catchError(error => {
                                console.error('HTTP POST error:', error);
                                return of(null); // Handle HTTP error and continue
                            }),
                            finalize(() => {
                                // Always run Facebook query, regardless of success or error in the HTTP request
                                let purchaseData: PurchaseData = {   
                                    currency: "USD",       
                                    value: order.totalPrice.value,
                                    num_items: orderEntries.length,
                                    contents: orderEntries.map((entry) => {
                                        return { name : entry.productCode, quantity: entry.quantity }
                                    }),  
                                    content_type: "product",
                                    content_ids: orderEntries.map(entry=>entry.productCode),
                                }

                                let faceExtra: FacebookQueryExtra = {
                                    eventID: postBody.eventId
                                };

                                this.facebookQuery("track", "Purchase", purchaseData, faceExtra);                                                                                                               
                            })
                        );
                    })
                );
            }),
        );
    }    

    viewProduct(productCode: string) {           
        if (this.winRef.isBrowser()) {
            this.initCode$.pipe(
                switchMap(() => this.getCommonMetaBody()),
                take(1)
            ).subscribe((commonBody: CommonMetaBody) => {            
                    const metaUrl = this.occEndpointsService.buildUrl('metaViewProduct');

                    const httpHeaders = new HttpHeaders({
                        'Content-Type': 'application/json',
                    });

                    const postBody = {
                        ...commonBody,
                        productCode: productCode                   
                    } 
            
                    this.http.post(metaUrl, postBody, { headers: httpHeaders }).subscribe(
                        response => console.log('success:', response),
                        error => console.error('error:', error)
                    );
                        
                    let viewContentData: ViewContentData = {                  
                        content_type: "product",
                        content_ids: [productCode],
                    }

                    let faceExtra : FacebookQueryExtra = {
                        eventID:postBody.eventId
                    }

                    return this.facebookQuery("track", "ViewContent", viewContentData, faceExtra ); 
                });
        }                 
    }

    getCommonMetaBody(): Observable<any> {             
        return combineLatest([this.getEventId(), this.user$])            
            .pipe(   
                map(([eventId, user]) => ({
                    eventId: eventId,
                    eventTime: Math.floor(Date.now() / 1000),                     
                    sourceUrl:  window.location.href,
                    fbc: this.getCookie("_fbc"),
                    fbp: this.getCookie("_fbp"), //fb.1.1558571054389.1098115397",
                    email: user?.uid
                        ? CryptoJS.SHA256(user.uid.toLowerCase()).toString(CryptoJS.enc.Hex).toLowerCase()
                        : '',          
                    firstName: user?.firstName ? CryptoJS.SHA256(encodeURIComponent(user.firstName.toLowerCase())).toString(CryptoJS.enc.Hex).toLowerCase()
                    : '',
                    lastName: user?.lastName ? CryptoJS.SHA256(encodeURIComponent(user.lastName.toLowerCase())).toString(CryptoJS.enc.Hex).toLowerCase()
                    : ''      
                })
            )
        )
    }

    public getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        // console.log(document.cookie);
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;
    
        for (let i: number = 0; i < caLen; i += 1) {
          c = ca[i].replace(/^\s+/g, '');
          if (c.indexOf(cookieName) == 0) {
            // console.log(c.substring(cookieName.length, c.length))
            return c.substring(cookieName.length, c.length);
          }
        }
        return '';
    }
    
    getEventId(): Observable<string> {
        const timestamp = new Date().getTime(); 
        const uuid = uuidv4();
        return of(`p-${timestamp}-${uuid}`);  
    }

    addInitCode() {
        this.metaPixelId = environment.metaPixelId;
        this.facebookQuery('dataProcessingOptions', ['LDU'], 0, 0);
        this.facebookQuery('set', 'autoConfig', true, this.metaPixelId);
        this.facebookQuery('init', this.metaPixelId, { em: this.userEmail });
    }

    pageView() {
        if (this.winRef.isBrowser()) {
            this.initCode$.subscribe(() => {
                if (this.metaPixelId === undefined) {
                    this.facebookQuery('track', 'PageView');
                } else {
                this.facebookQuery('trackSingle', this.metaPixelId, 'PageView');
                }
            });
        }
    }    

    addScript(f: Window, b: Document, e: string, v: string, n?: any, t?: HTMLScriptElement, s?: HTMLScriptElement): FacebookQuery {
      if (f.fbq) { return f.fbq }

      n = f.fbq = function () {
          if (n.callMethod) {
              // eslint-disable-next-line prefer-spread, prefer-rest-params
              n.callMethod.apply(n, arguments)
          } else {
              // eslint-disable-next-line prefer-rest-params
              n.queue.push(arguments)
          }
      } as unknown as FacebookQuery

      if (!f._fbq) { f._fbq = n }

      n.push = n
      n.loaded = true
      n.version = '2.0'
      n.queue = []

      t = b.createElement(e) as HTMLScriptElement
      t.async = true
      t.src = v

      s = b.getElementsByTagName(e)[0] as HTMLScriptElement
      s.parentNode!.insertBefore(t, s)

      return n
  }
}

interface CommonMetaBody {
    eventId: string,
    eventTime: number
    clientIpAddress: string,    
    sourceUrl: string,
    fbc: string,
    fbp: string,
    email: string,
    firstName: string,
    lastName: string
}

        //     const lastThree =  this.userAccountFacade.get().pipe(first());

        //     //   const subscribe = lastThree.subscribe(val => console.log(`Last value: ${val.uid}`));
        //  this.subscription = lastThree.subscribe((data) => {
        //         console.log(this.userEmail + " -- is subscribe")
        //         if (data) {
        //             this.userEmail = data.uid;
        //             console.log(this.userEmail + " -- user email")
        //         }
        //         const $fbq = this.addScriptDefault();
        //         this.metaPixelId = environment.metaPixelId;
        //         $fbq('set', 'autoConfig', true, this.metaPixelId)
        //         $fbq('init', this.metaPixelId, {em: this.userEmail })
        //         console.log(this.userEmail + " -- user email2")    
        //         this.pageView($fbq) 

        //     })


        //          return this.setup($fbq)