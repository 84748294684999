import { Injectable, OnDestroy } from "@angular/core";
import { RoutingService, ScriptLoader, User, UserIdService, WindowRef } from "@spartacus/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { ListTrak, SCAItem } from "./listrak.model";
import { filter, take } from "rxjs/operators";
import { Cart, OrderEntry } from "@spartacus/cart/base/root/public_api";
import { Order } from "@spartacus/order/root/model";
import { ActiveCartFacade } from "@spartacus/cart/base/root";


/**
 * Wrapper for the Listrak functionalities.
 */
@Injectable({
  providedIn: 'root',
})
export class ListrakService implements OnDestroy {
  /**
   * Listrak script parameters
   * @private
   */
  private tid = '08azzJEVWNCe';
  private vid = '1';
  /**
   * The Listrak object added to the window object
   * by the framework.
   * @private
   */
  private _ltk: ListTrak | undefined;
  /**
   * Event system that is fired when the Listrak
   * library is available in the browser.
   * @private
   */
  private scriptReadyEvent = new BehaviorSubject(false);
  ready$ = this.scriptReadyEvent.asObservable();

  isUserLoggedIn!: String;
  guid!: string;

  private subscription = new Subscription();

  private cart$: Observable<Cart> = this.activeCartService.getActive();

  constructor(private scriptLoader: ScriptLoader,
              private winRef: WindowRef,
              private routingService: RoutingService,
              private activeCartService: ActiveCartFacade,
              private useridservice: UserIdService,) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Loads the framework Listrak
   */
  load(): void {
    if (this.winRef.isBrowser()) {
      this.scriptLoader.embedScript({
        src: `https://cdn.listrakbi.com/scripts/script.js?m=${ this.tid }&v=${ this.vid }`,
        attributes: {
          id: 'ltkSDK',
        },
        callback: (_) => {
          this._ltk = ( window as any )._ltk;
          this.scriptReadyEvent.next(true);
          this.startUrlSync();
        },
      });
    }
  }

  /**
   * Renders the Preference Center Iframe
   * (it assumes that the div is already in the page)
   */
  renderPreferenceCenter(): void {
    this.ready$.pipe(take(1)).subscribe((_) => {
      this._ltk?.LtkPrefCenterIframe.Render();
    });
  }

  removePipe(value: string): string {
    return value?.replace(/\|/g, ' ');
  }

  clearCart(): void {
    if (this.winRef.isBrowser()) {
      this.clearSessionTracker();
      this._ltk.SCA.ClearCart();
    } 
  }

  cartAbandoment(items: OrderEntry[]) {
    if (this.winRef.isBrowser()) {
      let total = 0;
      this.useridservice.getUserId().subscribe(value => {
        this.isUserLoggedIn = value;
      });

      if (items.length === 0) {
        this._ltk.SCA.ClearCart();
      } else {
        items?.forEach((cartItem) => {
          let color = 'N/A';
          let size = 'N/A';
          let ymm = 'N/A';

          if (this.findVariantValueName(cartItem, 'Color')) {
            color = this.findVariantValueName(cartItem, 'Color');
          }

          if (this.findVariantValueName(cartItem, 'Size')) {
            size = this.findVariantValueName(cartItem, 'Size');
          }

          if (cartItem.product.vehicleSpecific) {
            ymm = cartItem.yearMakeModel?.replace(/\|/g, ' ') || '';
          }

          let scaItem = new SCAItem(
            cartItem.product?.code,
            cartItem.quantity,
            cartItem.totalPrice?.value,
            cartItem.product?.name
          );

          scaItem.ImageUrl = cartItem.product?.images?.PRIMARY?.product?.url;
          scaItem.LinkUrl = location.origin + cartItem.product.url;
          scaItem.Meta1 = cartItem.partNumber;
          scaItem.Meta2 = ymm;
          scaItem.Meta3 = color;
          scaItem.Meta4 = size;

          this._ltk.SCA.AddItemEx(scaItem);
        });

        let baseUrl = this.winRef.location.origin.replace(/(^\w+:|^)\/\//, '');
        let cartPath = '';

        if(this.isUserLoggedIn != 'anonymous'){
          cartPath = `/checkout`;
        }else{
          this.cart$.subscribe(data => {
            this.guid = data.guid;
          })
          cartPath = `/cart?guid=${this.guid}`;
        }

        this._ltk.SCA.CartLink = `${ baseUrl }${ cartPath }`;
        this._ltk.SCA.Total = total;
        this._ltk.SCA.Submit();
      }
    }
  }

  browseAbandoment(url: string) {
    if (this.winRef.isBrowser()) {
      this.subscription.add(
        this.scriptReadyEvent
          .pipe(filter(Boolean), take(1))
          .subscribe((_) => {
            this._ltk.Activity?.AddPageBrowse(url);
          })
      );
    }
  }

  browseAbandomentPDP(sku: string) {
    if (this.winRef.isBrowser()) {
      this._ltk.Activity?.AddProductBrowse(sku);
    }
  }

  addToCartAlert(sku: string) {
    if (this.winRef.isBrowser()) {
      this._ltk.Activity?.AddProductAddedToCart(sku);
    }
  }

  emailCapture(userEmail: string = 'emailAddress') {
    if (this.winRef.isBrowser()) {
      this._ltk?.SCA?.CaptureEmail(userEmail);
    }
  }

  conversionTracking(order: Order) {
    if (this.winRef.isBrowser()) {
      const items = order.entries;

      let name = '';
      let email = '';

      if (order.user?.name) {
        name = order.user?.name;
      }
      if (order.user?.uid) {        
        email = order.user?.uid;
      }

      this._ltk.Order.SetCustomer(
        this.removeTextBeforePipe(email),
        order.paymentInfo?.billingAddress?.firstName,
        order.paymentInfo?.billingAddress?.lastName
      );
      this._ltk.Order.OrderNumber = order.code;
      this._ltk.Order.ItemTotal = order.deliveryItemsQuantity;
      this._ltk.Order.ShippingTotal = order.deliveryCost?.value;
      this._ltk.Order.TaxTotal = order.totalTax?.value;
      this._ltk.Order.HandlingTotal = 0;
      this._ltk.Order.OrderTotal = order.totalPrice?.value;

      items?.forEach((item) => {
        this._ltk.Order.AddItem(
          item.product?.code,
          item.quantity,
          item.totalPrice?.value
        );
      });

      this._ltk.Order.Submit();
    }
  }

  removeTextBeforePipe(str: string): string {
    if (str.includes('|')) {
      return str.split('|')[1].trim();
    }
    return str.trim(); 
  }

  private startUrlSync(): void {
    this.subscription.add(
      this.routingService.getRouterState().subscribe((route) => {
        this.browseAbandoment(route.state.url);
      })
    );
  }

  /**
   * reset session tracker before running ClearCart()
   */
  private clearSessionTracker(): void {
    this._ltk.SCA.CartLink = null;
    this._ltk.SCA.Total = null;
    this._ltk.SCA.Meta1 = null;
    this._ltk.SCA.Meta2 = null;
    this._ltk.SCA.Meta3 = null;
    this._ltk.SCA.Meta4 = null;
    this._ltk.SCA.Meta5 = null;
    this._ltk.SCA.items = [];
  }

  /**
   * Find Qualifier name or return empty string (falsy) if not present
   */
  private findVariantValueName(item: OrderEntry, qualifier: string): string {
    let variantValueName = '';

    item.product.allVariantsValues?.forEach((variantValue) => {
      if (variantValue.qualifier == qualifier) {
        variantValueName = variantValue.name;
      }
    });

    return variantValueName;
  }
}
