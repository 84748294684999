import { createReducer, on } from "@ngrx/store";
import { Addon, AddonsSelection, AutomotiveLogoSelection, ConfigurationSelection, EmbroiderySelection, LogoSelection, MatBindingSelection, MatStyleSelection, PersonalizedEmbroiderySelection, productConfigInitialState, ProductConfigStepSelection, ProductConfigurationState, ProductLineSelection } from "./product-configuration.state";
import { deepCloning } from "../../../../../../tools/tools";
import { ProductConfigurationActions } from "./index";
import { buildKeyChainForProductLines } from "../facade/product-configuration.tools";
import { ProductConfigStepType } from "../models/product-configuration.models";

export const productConfigurationReducer = createReducer(
  productConfigInitialState,
  on(ProductConfigurationActions.setState, (_, { state }) => {
    return state;
  }),
  on(ProductConfigurationActions.loadSubmodelsOk,
    (state, { submodels, productLine, vehicleCode }) => {
      return {
        ...state,
        submodels: [
          ...( state.submodels ?? [] ),
          {
            productLine,
            submodels,
            vehicleCode
          }
        ]
      }
    }),
  on(ProductConfigurationActions.loadSubModelOptionsOk,
    (state, { subModel, productLine, options, vehicle }) => {
      return {
        ...state,
        subModelOptions: [
          ...( state.subModelOptions ?? [] ),
          {
            productLine,
            subModel,
            options,
            vehicle
          }
        ]
      }
    }),
  on(ProductConfigurationActions.setSubModel,
    (state, { subModel, productLine }) => {
      return {
        ...state,
        activeSubModel: {
          productLine,
          subModel
        }
      }
    }),
  on(ProductConfigurationActions.loadProductConfigurationLoading,
    (state, { loading }) => {
      return {
        ...state,
        productConfigLoading: loading
      }
    }),
  on(ProductConfigurationActions.loadProductConfigurationOk,
    (state, { config }) => {
      const keyChain = buildKeyChainForProductLines(config.metaData);
      return {
        ...state,
        configurations: {
          ...state.configurations,
          [ keyChain ]: config
        }
      }
    }),
  on(ProductConfigurationActions.saveConfigSelection, (state, { vehicle }) => {
    const selection = deepCloning(state.activeSelectionConfig);
    selection.vehicle = vehicle;
    return {
      ...state,
      activeSelectionConfig: {} as any,
      productConfigurations: {
        ...state.productConfigurations,
        [ selection.productCode ]: selection
      }
    };
  }),
  on(ProductConfigurationActions.clearConfiguratorState, (state, { productCode }) => {
    let productConfigurations = deepCloning(state.productConfigurations);
    if (productCode && productConfigurations[ productCode ]) {
      delete productConfigurations[ productCode ];
    }
    return {
      ...state,
      configurator: {},
      productConfigurations
    }
  }),
  on(ProductConfigurationActions.resetConfiguratorState, (state, { productCode }) => {
    let productConfigurations = deepCloning(state.productConfigurations);
    if (productCode && productConfigurations[ productCode ]) {
      delete productConfigurations[ productCode ];
    }
    return {
      ...state,
      configurator: {},
      productConfigurations,
      activeSelectionConfig: {
        productCode,
        selections: []
      }
    }
  }),
  on(ProductConfigurationActions.initializeConfigurator, (state, { productCode, activeSelectionConfig }) => {
    activeSelectionConfig = activeSelectionConfig ?? { productCode } as ConfigurationSelection;
    return {
      ...state,
      activeSelectionConfig
    }
  }),
  on(ProductConfigurationActions.setSelectionProperty, (state, { prop, value }) => {
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        [ prop ]: value
      }
    }
  }),
  on(ProductConfigurationActions.toggleProductLine, (state, { productLine, option }) => {
    const newSelection: ProductLineSelection = {
      stepType: ProductConfigStepType.PRODUCT_LINES,
      id: productLine.id,
      description: productLine.userInterfaceDescription,
      option: option,
    };
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections: toggle(state, newSelection),
      },
    };
  }),
  on(ProductConfigurationActions.toggleMatStyle, (state, { selection }) => {
    const newSelection: MatStyleSelection = {
      stepType: ProductConfigStepType.MAT_STYLES,
      description: selection.option.userInterfaceDescription,
      option: selection.option,
    };
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections: toggle(state, newSelection),
      },
    };
  }),
  on(ProductConfigurationActions.setMatBinding, (state, { selection }) => {
    const newSelection: MatBindingSelection = {
      stepType: ProductConfigStepType.MAT_BINDINGS,
      ...selection,
    };
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections: set(state, newSelection),
      },
    };
  }),
  on(ProductConfigurationActions.clearMatBindingSelection, (state, { selection }) => {
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections: clear(state, selection)
      },
    };
  }),
  on(ProductConfigurationActions.setPersonalizedEmbroiderySelection, (state, { selection }) => {
    return {
      ...state,
      activeSelectionConfig: {
      ...state.activeSelectionConfig,
      selections: set(state, selection)
      }
    }
  }),
  on(ProductConfigurationActions.clearPersonalizedEmbroiderySelection, (state, { selection }) => {
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections: clear(state, selection)
      },
    };
  }),
  on(ProductConfigurationActions.setLogoSelection, (state, { selection }) => {
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections: set(state, selection)
      }
    }
  }),
  on(ProductConfigurationActions.clearLogoSelection, (state,  { selection }) => {
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections: clear(state, selection)
      },
    };
  }),
  on(ProductConfigurationActions.clearEmbroiderySelection, (state , { selection }) => {
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections: clear(state, selection)
      },
    };
  }),
  on(ProductConfigurationActions.setEmbroiderySelection, (state, { selection }) => {
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections: set(state, selection)
      }
    }
  }),
  on(ProductConfigurationActions.setAutomotiveLogoSelection, (state, {selection}) => {
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections: set(state, selection)
      }
    }
  }),
  on(ProductConfigurationActions.clearAutomotiveLogoSelection, (state, {selection}) => {
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections: clear(state, selection)
      }
    }
  }),
  on(ProductConfigurationActions.toggleAppliedAutomotiveLogo, (state, { selection }) => {
    const selections: ProductConfigStepSelection[] = deepCloning(state.activeSelectionConfig.selections ?? []);
    let logoIdx = selections?.findIndex(s => s.stepType === ProductConfigStepType.AUTOMOTIVE_LOGO);

    if (logoIdx >= 0) {
      const selectedLogo = selections[ logoIdx ] as AutomotiveLogoSelection;
      
      let logoIsApplied = selectedLogo.selectedMatStyles?.some(m => m.basePartNumber === selection.matStyleOption.basePartNumber);

      if (logoIsApplied) {
        selectedLogo.selectedMatStyles = selectedLogo.selectedMatStyles.filter(m => m.basePartNumber !== selection.matStyleOption.basePartNumber);
      } else {
        selectedLogo.selectedMatStyles = selectedLogo.selectedMatStyles ?? [];
        selectedLogo.selectedMatStyles.push(selection.matStyleOption);
      }
                
      selections[logoIdx] = {
        ...selectedLogo,
        selectedMatStyles: [...selectedLogo.selectedMatStyles],
      };
    } 
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections
      }
    }
  }),
  on(ProductConfigurationActions.toggleAddon,
    (state, { sku, productBase, price, product, removeSelection, addonVariants }) => {
      const selections = deepCloning(state.activeSelectionConfig.selections ?? []);
      const addonIdx = selections?.findIndex(s => s.stepType === ProductConfigStepType.ADDONS);
      const toAdd: Addon = {
        sku,
        productBase,
        price,
        product,
        addonVariants: addonVariants
      };
      if (addonIdx < 0 && !removeSelection) {
        selections.push({
          stepType: ProductConfigStepType.ADDONS,
          addons: [ toAdd ]
        } as AddonsSelection)
      } else {
        const addonsSelection = selections[ addonIdx ] as AddonsSelection;
        const i = addonsSelection?.addons?.findIndex(a => a.productBase === productBase);
        if (i >= 0) {
          addonsSelection.addons.splice(i, 1);
        } else if (!removeSelection) {
          addonsSelection.addons.push(toAdd);
        }
      }
      return {
        ...state,
        activeSelectionConfig: {
          ...state.activeSelectionConfig,
          selections
        }
      };
    }),
    on(ProductConfigurationActions.updatePersonalizedMatsState, (state, value ) => {
      return {
          ...state,
          personalizeMats: {
              ...state.personalizeMats,
              ...value
          }
      };
    }),
    on(ProductConfigurationActions.updateMatBindingTypeState, (state, value ) => {
      return {
          ...state,
          matBindingType: {
              ...state.matBindingType,
              ...value
          }
      };
    }),
)

function isProductLineSelection(selection: ProductConfigStepSelection): selection is ProductLineSelection {
  return selection.stepType === ProductConfigStepType.PRODUCT_LINES;
}

function isMatStyleSelection(selection: ProductConfigStepSelection): selection is MatStyleSelection {
  return selection.stepType === ProductConfigStepType.MAT_STYLES;
}

function isMatBindingSelection(selection: ProductConfigStepSelection): selection is MatBindingSelection {
  return selection.stepType === ProductConfigStepType.MAT_BINDINGS;
}

function isLogoSelection(selection: ProductConfigStepSelection): selection is LogoSelection {
  return selection.stepType === ProductConfigStepType.LOGOS;
}

function isAutomotiveLogoSelection(selection: ProductConfigStepSelection): selection is AutomotiveLogoSelection {
  return selection.stepType === ProductConfigStepType.AUTOMOTIVE_LOGO;
}

function isEmbroiderySelection(selection: ProductConfigStepSelection): selection is EmbroiderySelection {
  return selection.stepType === ProductConfigStepType.EMBROIDERY;
}

function isPersonalizedEmbroiderySelection(selection: ProductConfigStepSelection): selection is PersonalizedEmbroiderySelection {
  return selection.stepType === ProductConfigStepType.PERSONALIZED_EMBROIDERY;
}

const compareProductLineSelections = (existing: ProductLineSelection, newSelection: ProductLineSelection) => {
  return existing.option.basePartNumber === newSelection.option.basePartNumber;
};

const compareMatStyleSelections = (existing: MatStyleSelection, newSelection: MatStyleSelection) => {
  return existing.option.basePartNumber === newSelection.option.basePartNumber;
};


type SelectionHandlerMap = {
  [ProductConfigStepType.PRODUCT_LINES]: SelectionHandler<ProductLineSelection>;
  [ProductConfigStepType.MAT_STYLES]: SelectionHandler<MatStyleSelection>;
  [ProductConfigStepType.MAT_BINDINGS]: SelectionHandler<MatBindingSelection>;
  [ProductConfigStepType.LOGOS]: SelectionHandler<LogoSelection>;
  [ProductConfigStepType.EMBROIDERY]: SelectionHandler<EmbroiderySelection>;
  [ProductConfigStepType.PERSONALIZED_EMBROIDERY]: SelectionHandler<PersonalizedEmbroiderySelection>;
  [ProductConfigStepType.AUTOMOTIVE_LOGO]: SelectionHandler<AutomotiveLogoSelection>;
};

const selectionHandlers: SelectionHandlerMap = {
  [ProductConfigStepType.PRODUCT_LINES]: { 
    isType: isProductLineSelection,
    compare: compareProductLineSelections,
  },
  [ProductConfigStepType.MAT_STYLES]: {
    isType: isMatStyleSelection,
    compare: compareMatStyleSelections,
  },
  [ProductConfigStepType.MAT_BINDINGS]: {
    isType: isMatBindingSelection,
  },
  [ProductConfigStepType.LOGOS]: {
    isType: isLogoSelection,
  },
  [ProductConfigStepType.EMBROIDERY]: {
    isType: isEmbroiderySelection,
  },
  [ProductConfigStepType.PERSONALIZED_EMBROIDERY]: {
    isType: isPersonalizedEmbroiderySelection,
  },  
  [ProductConfigStepType.AUTOMOTIVE_LOGO]: {
    isType: isAutomotiveLogoSelection,
  },  
}  

const findIndexByStepType = (
  selections: ProductConfigStepSelection[], 
  newSelection: ProductConfigStepSelection
): number => {
  const handler = selectionHandlers[newSelection.stepType as keyof SelectionHandlerMap];

  return selections.findIndex(s => {
    if (s.stepType !== newSelection.stepType) {
      return false;
    }

    return handler.isType(s) && handler.isType(newSelection) && (handler.compare ? handler.compare(s as any, newSelection as any) : true);
  });
};

type SelectionHandler<T extends ProductConfigStepSelection> = {
  isType: (selection: ProductConfigStepSelection) => selection is T;
  compare?: (existing: T, newSelection: T) => boolean;
};

const handleSelection = (
  existingEntryHandler: (idx: number, selections: ProductConfigStepSelection[], newSelection?: ProductConfigStepSelection) => void,
  state: ProductConfigurationState,
  newSelection?: ProductConfigStepSelection
) => {
  const selections = deepCloning(state.activeSelectionConfig.selections ?? []);
  const idx = findIndexByStepType(selections, newSelection);

  if (idx >= 0) {
    existingEntryHandler(idx, selections, newSelection);
  } else if (newSelection) {
    selections.push(newSelection);
  }

  return selections;
}

const toggleEntryHandler = (
  idx: number,
  selections: ProductConfigStepSelection[]
) => { 
  selections.splice(idx, 1);
};

const setEntryHandler = (
  idx: number,
  selections: ProductConfigStepSelection[],
  newSelection: ProductConfigStepSelection
) => {
    selections[idx] = newSelection;
};

const toggle = (
  state: ProductConfigurationState,
  newSelection?: ProductConfigStepSelection
) => {
  return handleSelection(toggleEntryHandler, state, newSelection);
};

const set = (
  state: ProductConfigurationState,
  newSelection: ProductConfigStepSelection
) => {
  return handleSelection(setEntryHandler, state, newSelection);
};

const clear = (
  state: ProductConfigurationState,
  selection: ProductConfigStepSelection
) => {
  return state.activeSelectionConfig?.selections?.find(s => s.stepType == selection.stepType) ? toggle(state, selection) : state.activeSelectionConfig.selections;
};

