<div *ngIf="item.basePrice">
    <div *ngIf="item?.product.priceWithDiscount.value !== item.product.price.value; else noDiscount" class="cx-value mt-2">
      <span class="custom-price bold-common-font">
        {{ item?.product.priceWithDiscount.formattedValue }}
        <span class="bold-common-font custom-price__promotion">Sale</span>
        <span class="custom-price__og-price medium-common-font">{{ item.product.price.formattedValue }}</span>
        <span class="custom-price__discount medium-common-font">save
          {{ item?.product.discount.formattedValue }} ({{ item?.product.discountPercentage }}% off)</span>
      </span>
    </div>
  
    <ng-template #noDiscount>
      <div class="cx-value mt-2">
        <span class="custom-price bold-common-font">
          {{ 
            (
              (item?.quantity === 0 || !item?.quantity || !item?.relatedEntries || item?.relatedEntries?.length === 0) 
                ? item?.basePrice?.formattedValue 
                : (item?.basePrice?.value * item?.quantity / item?.relatedEntries?.length)
            ) | currency:'USD'
          }}
        </span>
      </div>
    </ng-template>
  </div>