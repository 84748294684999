import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BaseSiteService, GlobalMessageType, PageMetaService } from '@spartacus/core';
import { PageLayoutService } from '@spartacus/storefront';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';
import { SkuPageService } from './spartacus/features/sku-landing-page/skupage.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MetaPixel } from 'src/app/spartacus/features/meta-pixel/meta-pixel';
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';
import { GlobalMessageService } from '@spartacus/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy  {
  title = 'Cover Craft';
  baseSite$ = this.baseSiteService.getActive()
    .pipe(switchMap((baseSiteID: string) => (this.baseSiteService.get(baseSiteID))));
  subscriptions = new Subscription();
  theme = '';
  pageID = '';
  templateID = '';


  constructor (
    private pageLayoutService: PageLayoutService,
    private baseSiteService: BaseSiteService,
    private skuPageService: SkuPageService,
    private router: Router,
    private dataLayerService: DataLayerService,
    private globalMessageService: GlobalMessageService,
    private metaPixel: MetaPixel,
    private pageMetaService: PageMetaService) {  
  }

  ngOnInit(): void {
    this.getCMSConfigs();

    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      if(event.url.includes('guid')){
        var guid = event.url.split("guid=")[1].split("&")[0];
        localStorage.setItem("spartacus⚿covercraft⚿cart",`{"active":"${guid}"}`); 
        this.router.navigateByUrl("/cart").then(() => {
          window.location.reload();
        });
       }
    });

    this.trackPageView();
    this.trackErrors();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  getCMSConfigs() {
 
    this.pageID = 'non-cms-page';

    this.subscriptions.add(
      this.baseSite$.subscribe(baseSite => {
        if (baseSite?.theme) {
          this.theme = baseSite.theme;
        }
      })
    );

    this.subscriptions.add(      
      this.pageLayoutService.page$.subscribe(page => {           
        if (page.productCode) {
          this.skuPageService.setCurrentProductCode(page.productCode);
          this.metaPixel.viewProduct(page.productCode);
        }

        if (page.pageId) {
          this.pageID = page.pageId;
        }

        if (page.template) {
          this.templateID = page.template;
        }
      })
    );
  }

  private trackPageView() {
    this.subscriptions.add(
      combineLatest([
        this.pageLayoutService.page$,
        this.pageMetaService.getMeta()
      ]).pipe(
        filter(data => data[1].title !== undefined),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev[0]) == JSON.stringify(curr[0]))
      ).subscribe(([page, meta]) => {
        if (meta.title) {
          this.dataLayerService.pageViewEvent(page, meta);
          this.metaPixel.pageView();
        }
      })
    );
  }

  private trackErrors() {
    this.subscriptions.add(
      this.globalMessageService.get().pipe(debounceTime(50)).subscribe(msg => {
        if (msg[GlobalMessageType.MSG_TYPE_ERROR]) {
            let errMsg = msg[GlobalMessageType.MSG_TYPE_ERROR][0]?.key;
            if (errMsg) {
              this.dataLayerService.errorEvent(errMsg);
            }
          }
      })
    );
  }
}

